<template>
  <div class="background fill-height" @click="$router.push('/pod/' + $route.params.id)">
    <page-offset />

    <div class="countdown">
      <div class="countdown-label text-h6 text-uppercase text-center">
        Your show will begin in
      </div>

      <transition name="fade">
        <div class="timecode">
          {{ timecode }}
        </div>
      </transition>
    </div>
    <div class="countdown "></div>
  </div>
</template>

<script>
import PageOffset from '../layout/PageOffset.vue';

export default {
  data() {
    return {
      timecode: null,
      startTime: null,
      interval: null
    };
  },
  mounted() {
    const start = new Date();

    start.setHours(
      start.getHours() + Math.random() * 10,
      start.getMinutes() + Math.random() * 60
    );

    this.startTime = start;
    this.interval = setInterval(this.updateClock, 1000);
  },
  methods: {
    updateClock() {
      const currentTime = new Date();
      const elapsed = new Date(this.startTime - currentTime);

      const hours = this.zeroPrefix(elapsed.getUTCHours(), 2);
      const minutes = this.zeroPrefix(elapsed.getUTCMinutes(), 2);
      const seconds = this.zeroPrefix(elapsed.getUTCSeconds(), 2);

      this.timecode = `${hours}:${minutes}:${seconds}`;
    },
    zeroPrefix(num, digit) {
      return ('0'.repeat(digit) + num).slice(-digit);
    }
  },
  components: { PageOffset }
};
</script>
<style lang="scss" scoped>
.background,
.background::before {
  width: 100%;
  height: 100%;
}

.background {
  position: relative;

  display: flex;
  flex-flow: row nowrap;

  justify-content: center;
  align-items: center;
}

.background::before {
  content: '';
  background-image: url('https://www.atrafloor.com/app/uploads/2017/01/vertigo-abstract-line-pattern-flooring-blue.jpg');
  background-position: center;
  background-repeat: round;
  background-size: cover;

  filter: blur(30px);

  position: absolute;
}

.countdown {
  z-index: 10;
}

.timecode {
  font-size: 6rem;
  font-weight: 200;
}
</style>
